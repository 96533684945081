import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const TermsPage = () => (
  <Layout>
    <SEO title="利用規約" />
    <div className="site-container privacy-page">
      <div className="container">
        <h1>利用規約</h1>
        <div>
          <h2>1. はじめに</h2>
          <p>この利用規約（以下「本規約」）は、当方がApp Store、Google Play Storeで公開している下記のアプリをご利用いただくにあたり、定期購入機能（以下「本サービス」）の利用条件を定めるものです。本サービスをご利用いただく前に必ず本規約をお読みください。</p>
          <ul>
            <li>
              <a title="App Store" target="_blank" rel="noreferrer" href="https://apps.apple.com/us/developer/yuzuru-watanabe/id1564714966">App Store</a>
            </li>
            <li>
              <a title="Google Play Store" target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/developer?id=zb185423">Google Play Store</a>
            </li>
          </ul>
          <h2>2. 定期購入について</h2>
          <p>本サービスは、月額または年額の定期購入型サービスです。</p>
          <p>ユーザーが本サービスを購入すると、App Store、Google Play Storeを通じて定期的に料金が請求されます。</p>
          <p>支払いは、ユーザーが選択したサブスクリプション期間の終了時に自動的に更新されます。</p>

          <h2>3. 支払いと請求</h2>
          <p>サブスクリプションの支払いは、App Store、Google Play Storeアカウントを通じて行われます。</p>
          <p>ユーザーの同意を得た時点で、初回の請求が発生します。</p>
          <p>更新時の料金は、前回のサブスクリプション料金と同じ金額です。</p>

          <h2>4. キャンセルおよび返金</h2>
          <p>サブスクリプションのキャンセルは、App Store、Google Play Storeの設定から行うことができます。</p>
          <p>現在のサブスクリプション期間中にキャンセルされた場合でも、期間終了までのアクセス権は維持されます。</p>
          <p>サブスクリプション料金の返金は原則として行われません。ただし、App Store、Google Play Storeのポリシーに基づく場合を除きます。</p>

          <h2>5. 無料トライアル</h2>
          <p>無料トライアルが提供される場合、トライアル期間終了後は自動的にサブスクリプションに移行します。</p>
          <p>無料トライアル期間中にキャンセルしない場合、ユーザーのアカウントに料金が請求されます。</p>

          <h2>6. ユーザーの責任</h2>
          <p>ユーザーは、本サービスの利用に必要なデバイスを用意する責任を負います。</p>
          <p>ユーザーは、アカウント情報を正確に保持し、第三者に不正使用されないよう管理する責任を負います。</p>

          <h2>7. 免責事項</h2>
          <p>当社は、本サービスが中断しないこと、またはエラーが発生しないことを保証しません。</p>
          <p>本サービスの利用に関連して発生した損害について、当方は一切の責任を負いません。ただし、法的に免責が認められない場合を除きます。</p>

          <h2>8. 本規約の変更</h2>
          <p>当方は、本規約を随時変更することができます。</p>

          <h2>9. お問い合わせ</h2>
          <p>本サービスや本規約に関するご質問がある場合は、以下の連絡先までお問い合わせください。</p>
          <p>zb185423＠yahoo.co.jp</p>
        </div>
      </div>
    </div>
  </Layout>
);

export default TermsPage;
